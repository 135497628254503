<template>
  <locale-router-link
    tag="a"
    :class="`catalog-banner__item`"
    :key="item.id"
    :to="`/products/${item.id}`"
    :data-id="item.id"
  >
    <banner-card-caption :item="item" />
    <img
      class="catalog-banner__item-img"
      v-lazy="{ src: imageUrl(item), loading: imageLoading }"
      alt=""
    />
    <div class="catalog-banner__item-title">
      {{ item.attributes.name }}
    </div>
  </locale-router-link>
</template>

<script>
export default {
  name: "BannerCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    BannerCardCaption: () =>
      import("@/components/banner/BannerCardCaption.vue"),
  },
  methods: {
    imageUrl(item) {
      if (item?.attributes?.image?.data?.length) {
        return this.$helpers.getAbsolutePath(
          item?.attributes.image.data[0].attributes.url
        );
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
    imageLoading() {
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog-banner__item-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}
</style>
